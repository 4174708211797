import {
	json,
	type LoaderFunctionArgs,
	type MetaFunction,
} from '@remix-run/node'
import { Link, useLoaderData } from '@remix-run/react'
import { useTranslation } from 'react-i18next'
import { BlurContent } from '#app/components/blur-content.js'
import { GlogoCarousel } from '#app/components/glogo-carousel.js'
import { Image } from '#app/components/image.js'
import { RenderLocalizedContent } from '#app/components/render-localized-content.js'
import { Button } from '#app/components/ui/button.js'
import {
	Card,
	CardContent,
	CardFooter,
	CardHeader,
} from '#app/components/ui/card.js'
import { Typography } from '#app/components/ui/typography.js'
import {
	webEngDraftsman,
	webImageTeam,
	webMainImage,
} from '#app/images/index.js'
import i18nServer from '#app/modules/i18n.server.js'
import { getUserId } from '#app/utils/auth.server.js'
import { splitByComma } from '#app/utils/comma-operations.js'
import { prisma } from '#app/utils/db.server.js'
import { getCompanyLogoSrc } from '#app/utils/misc.js'

export const meta: MetaFunction<typeof loader> = ({ data }) => [
	{ title: data?.seo.title },
	{
		description: data?.seo.description,
	},
	{
		name: 'og:image',
		content: 'https://spacebrains.tech/app/images/seo/og.png',
	},
]

export async function loader({ request }: LoaderFunctionArgs) {
	const userId = await getUserId(request)

	const jobs = await prisma.job.findMany({
		select: {
			id: true,
			titleEn: true,
			titleSk: true,
			shortDescriptionEn: true,
			shortDescriptionSk: true,
			company: {
				select: {
					name: true,
					location: true,
					logo: true,
				},
			},
			tags: true,
		},
		take: userId ? 10 : 2,
	})

	// TODO: get applications on job query
	let applications = null

	if (userId) {
		applications = await prisma.application.findMany({
			where: {
				userId,
			},
			select: {
				id: true,
				jobId: true,
			},
		})
	}

	// TODO: improve this query
	// We should get only tags for jobs that are in the jobs array
	const tags = await prisma.selector.findMany({
		where: {
			type: 'tags',
		},
		select: {
			id: true,
			slovakLabel: true,
			englishLabel: true,
			type: true,
		},
	})

	const t = await i18nServer.getFixedT(request)
	const seo = {
		title: t('seo.home.title'),
		description: t('seo.home.description'),
	}

	return json({
		jobs,
		isLoggedIn: !!userId,
		applications,
		tags,
		seo,
	})
}

export default function Index() {
	const data = useLoaderData<typeof loader>()

	const { t } = useTranslation()

	return (
		<main className="container mx-auto flex flex-col gap-4 md:gap-12">
			<section>
				<div className="grid items-center gap-y-8 md:grid-cols-2">
					<div className="order-2 flex flex-col gap-12 p-0 md:order-1 md:p-8">
						<Typography.H1>
							{t('homepage.engineerJobLanding.title')}
						</Typography.H1>
						<Button className="max-w-min whitespace-nowrap" asChild>
							<Link to="/login">{t('homepage.engineerJobLanding.cta')}</Link>
						</Button>
					</div>
					<Image src={webMainImage} className="order-1 md:order-2" />
				</div>
			</section>
			<section>
				<Typography.H2 className="mb-6 block">
					{t('homepage.highlightedJobOpenings.title')}
				</Typography.H2>
				<div className="grid gap-8 gap-y-8 md:grid-cols-2 ">
					{data.jobs?.map(job => {
						const logo = getCompanyLogoSrc(job.company.logo?.id)
						const tags = splitByComma(job.tags)

						return (
							<Card key={job.id} className="flex h-full flex-col">
								<CardHeader>
									<Typography.H3>
										<RenderLocalizedContent en={job.titleEn} sk={job.titleSk} />
									</Typography.H3>
								</CardHeader>
								<CardContent className="flex-grow">
									<div className="mt-4 flex flex-wrap gap-2">
										{tags.map(tag => {
											const content = data.tags.find(t => t.id === tag)

											return (
												<span
													key={tag}
													className="rounded-full bg-primary-100 px-3 py-1 text-sm text-primary-800"
												>
													<RenderLocalizedContent
														en={content?.englishLabel ?? ''}
														sk={content?.slovakLabel ?? ''}
													/>
												</span>
											)
										})}
									</div>
								</CardContent>
								<CardFooter className="flex flex-col items-start gap-4 md:flex-row md:items-center md:justify-between">
									<div className="flex items-center gap-6">
										<BlurContent blur={!data.isLoggedIn}>
											{logo ? (
												<img
													src={logo}
													alt={job.company.name}
													className="max-h-[75px] object-cover"
												/>
											) : null}
										</BlurContent>
										<div>
											<BlurContent blur={!data.isLoggedIn}>
												<Typography.Small>{job.company?.name}</Typography.Small>
											</BlurContent>
											<Typography.Muted>
												{job.company?.location}
											</Typography.Muted>
										</div>
									</div>
									<Button asChild>
										<Link to={`/jobs/${job.id}`} className="w-full md:w-auto">
											{t('jobsPage.findOutMore')}
										</Link>
									</Button>
								</CardFooter>
							</Card>
						)
					})}
				</div>
				<Typography.Muted className="mt-4 block text-center" asChild>
					<Link to="/jobs">{t('homepage.highlightedJobOpenings.cta')}</Link>
				</Typography.Muted>
			</section>
			<section className="grid items-center gap-y-8 md:grid-cols-2">
				<Image src={webImageTeam} className="rounded-md shadow-lg md:h-full" />
				<div className="flex flex-col gap-12 p-0 md:p-8">
					<div>
						<Typography.H2>
							{t('homepage.preVettedEngineers.title')}
						</Typography.H2>
						<Typography.Paragraph>
							{t('homepage.preVettedEngineers.description')}
						</Typography.Paragraph>
					</div>
					<div className="flex gap-4">
						<Button asChild>
							<a
								href="https://calendly.com/spacebrains"
								target="_blank"
								rel="noopener noreferrer"
							>
								{t('homepage.preVettedEngineers.primaryAction')}
							</a>
						</Button>
						<Button variant="outline" asChild>
							<Link to="/companies">
								{t('homepage.preVettedEngineers.secondaryAction')}
							</Link>
						</Button>
					</div>
				</div>
			</section>
			<section>
				<Typography.H4 className="text-center">
					{t('partnerWithUs')}
				</Typography.H4>
				<GlogoCarousel />
			</section>
			<section className="grid items-center gap-y-8 md:grid-cols-2">
				<div className="order-2 flex flex-col gap-12 p-0 md:order-1 md:p-8">
					<div>
						<Typography.H2>{t('homepage.engineerFocus.title')}</Typography.H2>
						<Typography.Paragraph>
							{t('homepage.engineerFocus.description')}
						</Typography.Paragraph>
					</div>
					<div className="flex gap-4">
						<Button asChild>
							<Link to="/login">
								{t('homepage.engineerFocus.primaryAction')}
							</Link>
						</Button>
						<Button variant="outline" asChild>
							<Link to="/engineers">
								{t('homepage.engineerFocus.secondaryAction')}
							</Link>
						</Button>
					</div>
				</div>
				<Image src={webEngDraftsman} className="order-1 md:order-2" />
			</section>
		</main>
	)
}
